import { isMobile } from '../utils/index'
export default {
  address: '桃園市桃園區民生路107號',
  googleSrc:
    'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7232.445824296749!2d121.30899207635721!3d24.99254040000001!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x34681f5fbb4bd221%3A0x7ab4e3c5f61d01ad!2z55ub6Zuy5bq35a-TIOWFrOWvk-W8j-mFkuW6lyBIZWFsdGh5IFJlc2lkZW5jZQ!5e0!3m2!1szh-TW!2stw!4v1662604178524!5m2!1szh-TW!2stw',
  googleLink: 'https://goo.gl/maps/3azzCs3rMXDe6qrt8',
  phone: '03-325-9925',
  fbLink: 'https://www.facebook.com/102447382596524',
  fbMessage: 'https://m.me/102447382596524',
  caseName: '智醫康寓',
  indigatorLength: 10,

  houseInfos: [
    ['產權所有', '盛弘醫藥股份有限公司（股票代號8403）',],
    ['經營管理', '智醫城股份有限公司',],
    ['行銷企劃', '鴻智行銷-鴻慧開發有限公司',],
    ['服務據點', '桃園市桃園區中正路1048號5樓',],
    ['官方帳號 ', 'line ID：@ywa1585o',],
  ],

  gtmCode: ['5P6KGCX'], // 可放置多個
  recaptcha_site_key_v2: '6LfGUjEaAAAAANYvhZQx5imGm23pRt0V-rOvkpNC',
  recaptcha_site_key: '6Lck-L8UAAAAABxfvNA1NJuBrdl3iLFc3GkudC8s', // recaptcha v3
  recaptcha_user_token: '6Lck-L8UAAAAAIcvenwGDl8_Q1tKbrEYsKuriePa',
  order: {
    title: '立即諮詢',
    // subTitle: '若想了解更多資訊，歡迎填寫表單或來電洽詢，將由專人為您服務，謝謝！
    room_type: ['單人房','雙人房'],//房型
  },
  lixin_logo:true, //false

}