<template>
<svg viewBox="0 0 82 45" xmlns="http://www.w3.org/2000/svg">
<path d="M20 16C20 16 24 0 41 0C53 0 61 4 66 20C78 25 82 37 82 45H0C0 45 -4 25 20 16Z" />
</svg>
</template>
<style lang="scss" scoped>
</style>

<script>
export default {
  name: 'cloud',
  components: {},

  methods: {},
}
</script>
