export default [
  /* */
  { name: '關於康寓', imgSrc: '', subTitle: '', section: 'section2', svgSrc: '',offset:-60,mobileOffset:0 , type: '' },
  { name: '優惠活動', imgSrc: '', subTitle: '', section: 'promotions', svgSrc: '',offset:-60,mobileOffset:0,  type: '' },
  { name: '市區生活', imgSrc: '', subTitle: '', section: 'section3', svgSrc: '',offset:-60,mobileOffset:0,  type: '' },
  { name: '百貨商圈', imgSrc: '', subTitle: '', section: 'section4', svgSrc: '',offset:-60,mobileOffset:0, type: '' },
  { name: '精緻寓所', imgSrc: '', subTitle: '', section: 'section5', svgSrc: '',offset:-60,mobileOffset:0 , type: '' },
  { name: '商務服務', imgSrc: '', subTitle: '', section: 'section6', svgSrc: '',offset:-60,mobileOffset:0 , type: '' },
  { name: '康健生活', imgSrc: '', subTitle: '', section: 'section7', svgSrc: '',offset:-60,mobileOffset:0 , type: '' },
  { name: '交通資訊', imgSrc: '', subTitle: '', section: 'section8', svgSrc: '',offset:-60,mobileOffset:0 , type: '' },
  // { name: '樣品屋預留', imgSrc: '', subTitle: '', section: 'section9', svgSrc: '',offset:-60, mobileOffset:0 , type: '' },
  
  { name: '立即來電', imgSrc: '', subTitle: '', section: 'contact-info', svgSrc: '',offset:-60,mobileOffset:0, type: 'btn' },
  { name: '地圖導航', imgSrc: '', subTitle: '', section: 'google-map', svgSrc: '',offset:-60, mobileOffset:0, type: 'btn' },
  { name: '立即諮詢', imgSrc: '', subTitle: '', section: 'contact', svgSrc: '',offset:-60, mobileOffset:0, type: 'btn' },
]